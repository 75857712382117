function customSelect(e) {
    var $this = e, numberOfOptions = e.children('option').length;
    var _hideIcons = false;
    var _searchable = false;
    var _classes = '';
    var _disabled = false;
    if($this.attr('hideIcons') !== undefined) {
        _hideIcons = true;
    }
    if($this.attr('searchable') !== undefined) {
        _searchable = true;
    }
    if($this.attr('class') !== undefined) {
        _classes = $this.attr('class');
    }

    _disabled = $this.hasClass('select-styled__disabled');

    //remove old init if there is
    if ($this.parent().is('div.select-styled')) {
        $this.unwrap();
    }
    $this.next('.select-styled').remove();
    $this.next('.select-options').remove();


    $this.addClass('select-hidden');
    $this.wrap('<div class="select-wrap"></div>');
    $this.after('<div class="select-styled ' + _classes.replace('select-hidden', '') + '"></div>');
    const worldIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="me-1 pi pi-all-countries-bold-fill" viewBox="0 0 16 16">\n' +
        '<path d="M8.08 0A5.583 5.583 0 0 0 6.4 4c0 1.19.371 2.293 1.004 3.2H4.3c.16-2.32 1-4.14 1.84-5.43.47-.71.93-1.26 1.28-1.62.02-.03.11-.12.13-.14A5.887 5.887 0 0 1 8.08 0Zm1.034 8.8a5.566 5.566 0 0 0 2.415.78 11.375 11.375 0 0 1-1.759 4.65c-.46.71-.93 1.26-1.28 1.62-.04.05-.09.1-.13.14-.13.01-.42.01-.42.01-.071 0-.143-.002-.213-.005-.067-.002-.133-.005-.197-.005-.04-.05-.08-.09-.13-.14-.34-.36-.81-.9-1.28-1.62A11.28 11.28 0 0 1 4.28 8.8h4.834Zm4.068.675a5.566 5.566 0 0 0 1.704-.675h1.034c-.3 3.03-2.3 5.56-5.02 6.64a2.36 2.36 0 0 0 .134-.19c.031-.046.063-.094.096-.14a12.906 12.906 0 0 0 2.052-5.635ZM4.886.75A2.36 2.36 0 0 1 5.02.56 8 8 0 0 0 0 7.2h2.68A12.864 12.864 0 0 1 4.886.75ZM0 8.8h2.68c.17 2.7 1.14 4.83 2.11 6.31.08.11.16.22.23.33A8 8 0 0 1 0 8.8ZM16 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-6.046-.478a.63.63 0 0 0 0 .89l1.186 1.185a.63.63 0 0 0 .912-.024l2.134-2.37a.63.63 0 0 0-.936-.842l-1.69 1.877-.716-.716a.63.63 0 0 0-.89 0Z"/>\n' +
        '</svg>'
    var $styledSelect = $this.next('div.select-styled');

    if(!_hideIcons) {
        if ($this.children('option:selected').data('flag') && $this.children('option:selected').data('flag') !== "world") {
            $styledSelect.append('<span class="country-flag-24-2x d-inline-block me-1 is-' + $this.children('option:selected').data('flag') + '"></span>')
        } else {
            $styledSelect.append(worldIcon)
        }
    }

    $styledSelect.append('<span class="select-value">' + $this.children('option:selected').text() + '<span>');

    var $list = $('<ul />').insertAfter($styledSelect);
    $list.wrap('<div class="select-options"></div>');

    for (var i = 0; i < numberOfOptions; i++) {
        var _flag = '';
        var _attr = {
            rel: $this.children('option').eq(i).val(),
            class: 'select-options-item'
        };
        if (!_hideIcons) {
            if($this.children('option').eq(i).data('flag') !== "world") {
                _flag = '<span class="country-flag-24-2x fix-icon d-inline-block me-1 is-' + $this.children('option').eq(i).data('flag') + '"></span>';
                _attr.flag = $this.children('option').eq(i).data('flag');
            } else {
                _flag = worldIcon
                _attr.flag = "world";
            }
        }
        _attr.html = _flag + '<span class="select-value">' + $this.children('option').eq(i).text() + '<span>';
        if($this.children('option').eq(i).data('empty') === undefined) {
            $('<li />', _attr).appendTo($list);
        }
    }

    var $listItems = $list.children('li');

    $styledSelect.click(function (e) {
        e.stopPropagation();
        if(!_disabled) {
            $('div.select-styled.active').not(this).each(function () {
                $(this).removeClass('active').next('.select-options').hide();
            });
            $(this).toggleClass('active').next('.select-options').toggle();
        }
    });

    $listItems.click(function (e) {
        e.stopPropagation();
        if(!_disabled) {
            var _flag = '';
            if (!_hideIcons) {
                if($(this).attr('flag') !== "world") {
                    _flag = '<span class="country-flag-24-2x d-inline-block me-1 is-' + $(this).attr('flag') + '"></span>';
                } else {
                    _flag = worldIcon
                }
            }

            $styledSelect.html(_flag + '<span class="select-value">' + $(this).text() + '<span>').removeClass('active');
            $($listItems).removeClass('active');
            setTimeout(() => {
                $(this).addClass('active');
            }, 10);
            $this.val($(this).attr('rel'));
            $list.parent().hide();
            $('#position-error').hide();
            $('#' + $this.attr('id') + ' option').removeAttr("selected", "selected");
            $('#' + $this.attr('id') + ' option[value="' + $this.val() + '"]').attr("selected", "selected");
            $('#' + $this.attr('id')).change();
            if(_searchable) {
                $search.find('input').val('');
            }
        }
    });

    $(document).click(function () {
        $styledSelect.removeClass('active');
        $list.parent().hide();
    });

    if(_searchable) {
        var $search = $(
            `<div class="p-1">
                <input type="text" class="form-control form-control-sm bg-pv-neutrals-50 select-options-search" placeholder="${window.djangoData.searchText}" />
                <span></span>
                <div class="empty-message hide">${window.djangoData.listIsEmptyText}</div>
            </div>`
        ).insertBefore($list);
        $search.find('input')
            .click(function (e) {e.stopPropagation();})
            .on('input', function() {
                const _searchText = this.value;
                $($listItems).removeClass('hide');
                if(_searchText?.length >0) {
                    $($listItems).each(function() {
                        if($(this).text().toLowerCase().indexOf(_searchText.toLowerCase()) === -1) {
                            $(this).addClass('hide');
                        }
                    });
                }
                $search.find('.empty-message').addClass('hide');
                if($($listItems).filter(':not(.hide)').length === 0) {
                    $search.find('.empty-message').removeClass('hide');
                }
            });
    }
}

window.customSelect = customSelect;

