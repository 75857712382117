export const Cookie = {
    set(name, value, days) {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toGMTString();
        }

        const host = location.hostname;
        let domain;
        if (host.split('.').length === 1) {
            document.cookie = name + '=' + value + expires + '; secure=true; path=/';
        } else {
            domain = '.' + host.split('.').splice(-2, 2).join('.');
            document.cookie = name + '=' + value + expires + '; path=/; secure=true; domain=' + domain;

            if (Cookie.get(name) == null || Cookie.get(name) != value) {
                domain = '.' + host;
                document.cookie = name + '=' + value + expires + '; path=/; secure=true; domain=' + domain;
            }
        }
    },

    get(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }

            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },

    erase(name) {
        Cookie.set(name, '', -1);
    }
};