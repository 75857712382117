export function generateAvatar(user) {
    const avatarElement = document.querySelector('.user-avatar');
    let initial = user.name.charAt(2);
    if (window.djangoData.useNetUser) {
        initial = user.name.charAt(0);
    }
    const color = stringToColor(user.name);
    avatarElement.style.backgroundColor = color;
    avatarElement.textContent = initial;
}

function stringToColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}