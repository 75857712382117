import $ from 'jquery';
window.jQuery = $;
window.$ = $;
export default $;

import { Tooltip, Dropdown, Popover } from 'bootstrap';
import customSelect from './components/select';
import { Notyf } from 'notyf';
const notyf = new Notyf({
    duration: 4000,
    position: {
        x: 'right',
        y: 'top',
    }
});
window.notyf = notyf;
import { generateAvatar } from './components/avatar';
import { setupLanguageSwitcher } from './components/languageSwitcher';
import { Cookie } from './components/cookies';


const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));


document.addEventListener('DOMContentLoaded', function () {
    if (window.djangoData.username) {
        const user = {
            name: window.djangoData.username
        };
        generateAvatar(user);
    }


    const currentLang = window.djangoData.languageCode;
    setupLanguageSwitcher(currentLang);

    $('#userNameDesktop').html($('#dropdownUserInfo').html());
    $('#userEmailDesktop').text($('#userEmail').text());
    $('#userPlanDesktop').html($('.app-user-bar__plan').html());


    if (window.djangoData.authToken) {
        Cookie.set('statusAuth', 'login');
        Cookie.set('statusAuthPlan', window.djangoData.currentPlan);
        Cookie.set('statusAuthToken', window.djangoData.authToken);
    } else {
        Cookie.erase('statusAuth');
        Cookie.erase('statusAuthPlan');
        Cookie.erase('statusAuthToken');
    }
});

$(document).ready(function () {

    function toggleDropdownMenu() {
        if ($(window).width() < 1800) {
            $('.navbar-nav').addClass('dropdown-menu dropdown-menu-end');
        } else {
            $('.navbar-nav').removeClass('dropdown-menu dropdown-menu-end');
        }
    }

    toggleDropdownMenu();

    $('.app-sidebar__toggle').click(function () {
        $('.app-sidebar').toggleClass('app-sidebar--show');
        $('.app-page').toggleClass('app-page--hide-sidebar');
        $(this).toggleClass('app-sidebar__toggle--hide');
        $('.app-sidebar__close').toggleClass('d-none');
    });

    $('.app-sidebar__close').click(function () {
        $('.app-sidebar').removeClass('app-sidebar--show');
        $(this).toggleClass('d-none');
    });

    $(window).resize(function () {
        toggleDropdownMenu();
        if (window.matchMedia("(min-width: 1199.98px)").matches) {
            $('.app-sidebar').removeClass('app-sidebar--show');
            $('.app-sidebar__close').addClass('d-none');
        }
    });

    $('.btn-clipboard').click(function () {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($('input.copy-to-clipboard').val()).select();
        document.execCommand("copy");
        $temp.remove();

        $('.popup-copied').show();
        setTimeout(function () {
            $('.popup-copied').hide();
        }, 300);
    });

    $('.account .details-container .item-field .field-wrapper .show-pass-btn').click(function(){
        togglePassword(this);
    });

    $('.account .vpn-setup .socks-cred-row .show').click(function () {
        togglePassword(this);
    });

    $('.page-warpper .content .plan').click(function () {
        $('.page-warpper .content .plan').removeClass('active');
        $(this).addClass('active');
    });

    function togglePassword(_this) {
        var _field = $(_this).parent().find('input');
        if (_field.attr('type') === 'password') {
            _field.attr('type', 'text');
            $(_this).removeClass('eye-opened').addClass('eye-closed');
        } else {
            _field.attr('type', 'password');
            $(_this).removeClass('eye-closed').addClass('eye-opened');
        }
    }
});
